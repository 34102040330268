import './main.scss'
import AOS from 'aos';
import 'aos/dist/aos.css';

(function () {
  'use strict';
  window.addEventListener('load', function () {
    AOS.init();

    // Fetch all the forms we want to apply custom Bootstrap validation styles to
    var forms = document.getElementsByClassName('needs-validation');
    // Loop over them and prevent submission
    var validation = Array.prototype.filter.call(forms, function (form) {
      form.addEventListener('submit', function (event) {
        if (form.checkValidity() === false) {
          event.preventDefault();
          event.stopPropagation();
        }
        form.classList.add('was-validated');
      }, false);
    });
  }, false);

  // $('input,select').on('focus', function () {
  //   $(this).parent().addClass('is-focused');
  // });

  // $('input,select').on('input', function () {
  //   if ($(this).val() == '') {
  //     $(this).parent().removeClass('is-active');
  //   } else {
  //     $(this).parent().addClass('is-active');
  //   }
  // });

  // $('input,select').on('blur', function () {
  //   $(this).parent().removeClass('is-focused');
  //   if ($(this).val() == '') {
  //     $(this).parent().removeClass('is-active');
  //   }
  // });

  // $('input,select').each(function () {
  //   if ($(this).val() == '') {
  //     $(this).parent().removeClass('is-active');
  //   } else {
  //     $(this).parent().addClass('is-active');
  //   }
  // });
  // $(".toggle-password").click(function () {
  //   $(this).toggleClass("show");
  //   var input = $($(this).attr("toggle"));
  //   if (input.attr("type") == "password") {
  //     input.attr("type", "text");
  //   } else {
  //     input.attr("type", "password");
  //   }
  // });

})();
